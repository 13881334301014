@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,100;1,300&display=swap');

body {
  margin: 0;
  background-image: url('../asset/bg.jpg');
  background-position: center;
  background-size: cover;
}

.centered {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.header {
  z-index: 100;
  padding: 2rem;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}





#container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adminsvg {
    
}


#logoContainer {
  top: 0;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

#logo {
  width: 60px;
}

.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4.5rem;
  z-index: 11;
}

.footerlastcontainer {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 1rem;
  width: 100vw;
}

.footerlast {
  width: 70vw;
  display: flex;
  justify-content: space-evenly;
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
  color: blue;
  margin-left: auto;
  margin-right: auto;
}


.footer div {
  background-color: red;
  border: 2px solid white;
  color: white;
  text-transform: uppercase;
  padding: 3px;
  text-align: center;
  flex: 1;
  margin: 0px 1rem;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
}

.begin, .end {
  flex: 2 !important;
}

.begin {
  margin-left: 0px !important;
  border-left: none!important;
  cursor: pointer;
}

.end {
  margin-right: 0px !important;
  border-right: none!important;
}

#woman {
  display: none;
  position: absolute;
  top: 0;
  margin-bottom: 0;
  margin-top: auto;
  z-index: 10;
}

#logoradio {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 3rem;
  margin-left: 3rem;
  max-width: 15rem;
}

.blink {
  animation: blinker 1s linear infinite;
  -webkit-text-stroke: 2px gold;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#bonus {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 5rem;
  margin-top: 5rem;
  margin-right: 5rem;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: red;  

}